<template>
  <base-layout class="home-view">home</base-layout>
</template>

<script>
export default {
  components: {},

  data() {
    return {};
  },

  async created() {
    console.log(`home created`);
  },

  methods: {}
};
</script>

<style lang="less" scoped>
.home-view {
  font-size: 20px;
}
</style>
